<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />

    <div class="title-mark pl-12 mb-24">基础信息</div>

    <a-form ref="formRef"
            :model="live"
            :rules="liveRules"
            scrollToFirstError
            :labelCol="{style: 'width: 96px'}">

      <a-form-item label="直播主题"
                   name="title">
        <a-input v-model:value="live.title"
                 placeholder="请输入直播主题"
                 style="width: 320px">
          <template #suffix>
            <span class="text-color-999">{{live.title.length}} / 60</span>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item :class="{'has-error': !validateForm.startTime}"
                   label="直播开始"
                   required>
        <template v-if="!validateForm.startTime"
                  #help>
          <p class="text-danger">{{ timeError }}</p>
        </template>
        <a-date-picker v-model:value="live.startDate"
                       :disabled-date="disabledDate"
                       valueFormat="YYYY-MM-DD"
                       style="width: 180px; margin-right: 16px"
                       @change="handleStartTime" />
        <a-time-picker v-model:value="live.startSecond"
                       valueFormat="HH:mm:ss"
                       format="HH:mm"
                       hideDisabledOptions
                       style="width: 124px"
                       :minuteStep="5"
                       @change="handleStartTime" />
      </a-form-item>

      <a-form-item label="时长"
                   name="seconds">
        <a-select v-model:value="live.seconds"
                  placeholder="请选择直播时长"
                  optionLabelProp="label"
                  :options="timeOptions"
                  style="width: 160px">
        </a-select>
      </a-form-item>

      <a-form-item class="mb-0"
                   label="简介"
                   name="summary">
        <a-textarea v-model:value="live.summary"
                    showCount
                    :maxlength="300"
                    style="width: 464px"
                    :autoSize="{ minRows: 6, maxRows: 6 }"></a-textarea>
      </a-form-item>

      <a-form-item label="直播封面"
                   name="liveCover">
        <upload-image :apiName="'live'"
                      desc="建议尺寸750*422或比例16:9，小于2M的JPG、PNG格式图片。"
                      :imageUrl="live.liveCover"
                      @child-image="handleImgUrl" />
      </a-form-item>

      <a-form-item label="讲师设置"
                   required>
        <template v-if="!validateForm.teacherId"
                  #help>
          <p class="text-danger">请选择内部讲师</p>
        </template>

        <a-radio-group v-model:value="live.teacherSource"
                       @change="handleTeacherSource">
          <a-radio value="internal">内部讲师</a-radio>
          <a-radio value="external">外部讲师</a-radio>
        </a-radio-group>

        <a-popover placement="right">
          <template #content>
            <p class="text-color-333">
              内部讲师：内部员工，做直播时用企业微信登录。
            </p>
            <p class="text-color-333">
              外部讲师：使用个人微信做直播。
            </p>
          </template>
          <svg-icon class="text-16 text-color-999"
                    type="iconyiwen" />
        </a-popover>

        <div>
          <template v-if="live.teacherSource == 'internal'">
            <a-popover :overlayStyle="{maxHeight: '300px'}"
                       trigger="click"
                       placement="right"
                       :getPopupContainer="() => $refs.addTeacherBtn">
              <template #content>
                <select-staff @choose="chooseInternalTeacher" />
              </template>
              <div ref="addTeacherBtn"
                   class="w-max">
                <a-button class="border-radius">
                  <PlusOutlined v-if="!internalTeacher.id" />{{ internalTeacher.id ? '重新选择讲师' : '添加讲师' }}
                </a-button>
              </div>
            </a-popover>

            <p v-if="internalTeacher.id"
               class="text-color-999">
              <!-- {{ internalTeacher.staffName }} -->
              已选择 <span class="font-medium text-admin-primary">「 <div v-is="'ww-open-data'"
                     class="ww-open-data"
                     type="userName"
                     :openid="internalTeacher.staffName"></div> 」</span> 为讲师
            </p>

          </template>

          <template v-else>
            <p v-if="!externalTeacher.id"
               class="flex items-center">
              <svg-icon type="icontishi"
                        class="mr-8 text-orange-4"></svg-icon> <span class="text-color-999">直播创建完成后，在公开课的“讲师”列设置外部讲师。</span>
            </p>

            <p v-else
               class="text-color-999">
              已邀请 <span class="font-medium text-admin-primary">「 {{ externalTeacher.nickName }} 」</span> 为讲师
            </p>
          </template>
        </div>

      </a-form-item>

      <a-form-item label="助教设置"
                   name="assistantIds"
                   :wrapperCol="{span: 16}">
        <a-button class="border-radius"
                  @click="selectStaffModalVisible = true">
          <PlusOutlined />添加助教
        </a-button>

        <template v-if="!validateForm.assistantIds"
                  #help>
          <p class="text-danger">内部讲师与助教重复</p>
        </template>

        <template v-if="staffList.length">
          <div class="flex flex-wrap mt-8">
            <a-tag v-for="staff in staffList"
                   :key="staff.staffId"
                   class="flex justify-between items-center flex-nowrap px-12 py-4"
                   :class="{'border-red-4 border-1 text-red-4': staff.staffName === internalTeacher.staffName }"
                   closable
                   @close="deleteAssistant(staff)">
              <div v-is="'ww-open-data'"
                   class="ww-open-data w-80 line-clamp-1"
                   type="userName"
                   :openid="staff.workWechatThirdAppUserId"></div>
            </a-tag>
          </div>
        </template>

        <select-staff-modal v-model:visible="selectStaffModalVisible"
                            :defaultCheckedKeys="checkedKeys"
                            @saveStaff="saveStaff"></select-staff-modal>
      </a-form-item>

      <div class="save-btn mt-80 mx-auto">
        <a-button class="btn"
                  type="primary"
                  :loading="submitLoading"
                  @click="saveLive">
          {{isEdit ? '保存' : '创建直播' }}
        </a-button>
      </div>
    </a-form>
  </a-spin>

</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import {
  DatePicker,
  TimePicker,
  Radio,
  Popover,
  message,
} from "ant-design-vue";
import dayjs from "dayjs";
import _ from "lodash";
import router from "@/router";
import { useRoute } from "vue-router";

import liveApi from "@/service/api/live";
import filters from "@/shared/service/filters";

import RouterHeader from "@/components/RouterHeader";
import SelectStaff from "@/components/SelectStaff";
import SelectStaffModal from "@/components/SelectStaffByGroup";
import SvgIcon from "@/components/SvgIcon";
import UploadImage from "@/components/UploadImage";

import "@/styles/components/content-styles.less";

export default defineComponent({
  name: "LiveOpenClassCreate",

  components: {
    RouterHeader,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ADatePicker: DatePicker,
    ATimePicker: TimePicker,
    APopover: Popover,

    SelectStaff,
    SelectStaffModal,
    SvgIcon,
    UploadImage,
  },

  setup() {
    const spinning = ref(true);

    const route = new useRoute();
    const { isEdit, id: roomId, setId } = route.query;

    const routerHeaderInfo = reactive([
      {
        path: "/marketingUtil/liveOpenClassIndex",
        name: "直播公开课",
      },
      { name: "新建直播" },
    ]);

    const LIVE_COVER_DEFAULT = process.env.VUE_APP_LIVE_COVER_DEFAULT;

    const live = reactive({
      title: "",
      startTime: "",
      endTime: "",

      teacherSource: "internal",
      teacherId: undefined,
      assistantIds: undefined,
      goodsId: undefined,
      seconds: "3600",

      startDate: filters.datetime(dayjs().valueOf(), "YYYY-MM-DD"),
      startSecond: undefined,

      liveCover: LIVE_COVER_DEFAULT,
      setId: setId,
    });

    const liveRules = {
      title: [
        {
          required: true,
          whiteSpace: true,
          message: "直播主题不能为空",
        },
        {
          max: 60,
          message: "直播主题长度应为1-60字",
        },
      ],

      seconds: [
        {
          required: true,
          message: "请选择直播时长",
        },
      ],

      liveCover: [
        {
          required: true,
          message: "请上传直播封面",
        },
      ],
    };

    // 判断为新建or编辑

    const formatLiveStartTime = () => {
      const DATE = new Date();
      const REMAINDER = DATE.getMinutes() % 5;

      const minute =
        REMAINDER === 0 ? DATE.getMinutes() : DATE.getMinutes() + 5 - REMAINDER;

      return dayjs().minute(minute).format("HH:mm");
    };

    const getLiveInfo = async () => {
      const res = await liveApi.infoRoom({
        roomId,
      });

      const {
        title,
        id,
        summary,
        startTime,
        endTime,
        teacherSource,
        teacherId,
        liveCover,
      } = res;

      _.assign(live, {
        title,
        id,
        summary,
        startTime,
        endTime,
        teacherSource,
        teacherId,
        liveCover,
      });

      initTeacher(res);
      initAssistantsId(res);
      initHours(res);
    };

    const initData = async () => {
      if (!isEdit) {
        live.startSecond = formatLiveStartTime();
        spinning.value = false;
        return;
      }

      routerHeaderInfo[1].name = "编辑直播";

      await getLiveInfo();

      validateLive();
      spinning.value = false;
    };

    const initTeacher = function (res) {
      if (!res.teacher) {
        return;
      }

      const { id, name } = res.teacher;

      res.teacherSource === "internal"
        ? _.assign(internalTeacher, { id, staffName: name })
        : _.assign(externalTeacher, { id, nickName: name });
    };

    const initAssistantsId = function (res) {
      if (!res.assistants?.length) {
        return;
      }

      _.forEach(res.assistants, (staff) => {
        const staffId = "staff_" + staff.id;

        staffList.value.push({
          staffId,
          staffName: staff.name,
          workWechatThirdAppUserId: staff.workWechatThirdAppUserId,
        });

        checkedKeys.value.push(staffId);
      });
    };

    const initHours = function (res) {
      const seconds = (res.endTime - res.startTime) / 1000;
      live.seconds = String(seconds);
      live.startDate = filters.datetime(res.startTime, "YYYY-MM-DD");
      live.startSecond = filters.datetime(res.startTime, "HH:mm");
    };

    // 直播开始
    const disabledDate = (current) => {
      return current < dayjs().startOf("day");
    };

    const handleStartTime = () => {
      validateForm.startTime = validateStartTime();
    };

    // 直播时长
    const timeOptions = ref([
      {
        value: "3600",
        label: "1小时",
      },
      {
        value: "5400",
        label: "1.5小时",
      },
      {
        value: "7200",
        label: "2小时",
      },
      {
        value: "9000",
        label: "2.5小时",
      },
      {
        value: "10800",
        label: "3小时",
      },
      {
        value: "14400",
        label: "4小时",
      },
      {
        value: "28800",
        label: "8小时",
      },
    ]);

    // 直播封面
    const handleImgUrl = function (imgurl) {
      live.liveCover = imgurl;
    };

    // 讲师设置
    const internalTeacher = reactive({});
    const externalTeacher = reactive({});

    const chooseInternalTeacher = (selectStaff) => {
      _.assign(internalTeacher, selectStaff);
      validateForm.teacherId = true;
    };

    const handleTeacherSource = () => {
      validateForm.teacherId = validateTeacher();
    };

    // 助教设置
    const selectStaffModalVisible = ref(false);
    const checkedKeys = ref([]);
    const staffList = ref([]);

    const saveStaff = async (list) => {
      console.log(list);
      staffList.value = list;
      checkedKeys.value = [];
      _.forEach(list, (staff) => {
        checkedKeys.value.push(staff.staffId);
      });

      validateForm.assistantIds = await validateAssistant();
      selectStaffModalVisible.value = false;
    };

    const deleteAssistant = async (deleteStaff) => {
      _.remove(staffList.value, (staff) => {
        return staff.staffId === deleteStaff.staffId;
      });

      _.remove(checkedKeys.value, (key) => {
        return key === deleteStaff.staffId;
      });

      validateForm.assistantIds = await validateAssistant();
    };

    // 新建直播
    const submitLoading = ref(false);

    const formatTeacher = () => {
      live.teacherId =
        live.teacherSource === "internal"
          ? internalTeacher.id
          : externalTeacher.id;
    };

    const formatStaffId = () => {
      let ids = [];
      checkedKeys.value = [];
      _.forEach(staffList.value, (staff) => {
        checkedKeys.value.push(staff.staffId);
        const id = _.split(staff.staffId, "_")[1];
        ids.push(id);
      });

      return ids;
    };

    const formatLiveTime = () => {
      const startTime = live.startDate + " " + live.startSecond;
      const setZeroMillsecond = dayjs(startTime, "YYYY-MM-DD HH:mm").second(0);
      live.startTime = setZeroMillsecond.valueOf();

      live.endTime = live.startTime + live.seconds * 1000;
    };

    const formRef = ref();

    const validateForm = reactive({
      startTime: true,
      teacherId: true,
      assistantIds: true,
    });

    const timeError = ref("");

    const validateStartTime = async () => {
      await formatLiveTime();

      if (!live.startDate || !live.startSecond) {
        timeError.value = "请选择直播开始时间";
        return false;
      }

      if (live.startTime < dayjs().valueOf()) {
        timeError.value = "直播开始时间不能早于当前时间";
        return false;
      }

      return true;
    };
    const validateTeacher = () => {
      if (live.teacherSource === "internal") {
        return internalTeacher.id;
      }

      return true;
    };
    const validateAssistant = async () => {
      live.assistantIds = await formatStaffId();
      if (live.teacherSource === "internal") {
        return !_.includes(live.assistantIds, String(internalTeacher.id));
      }

      return true;
    };

    const validateLive = async function () {
      validateForm.startTime = await validateStartTime();
      validateForm.teacherId = validateTeacher();
      validateForm.assistantIds = await validateAssistant();
    };

    const saveLive = _.debounce(async () => {
      submitLoading.value = true;

      await validateLive();

      formRef.value
        .validate()
        .then(async () => {
          if (!_.every(_.values(validateForm))) {
            return;
          }

          formatTeacher();

          if (isEdit) {
            live.id = roomId;
            await liveApi.updateRoom(live);
            message.success("编辑成功");
            router.push({
              name: "liveOpenClass_statistic",
              query: {
                id: roomId,
              },
            });
            return;
          }

          try {
            const queryList = await liveApi.createRoom(live);
            message.success("创建成功");

            router.push({
              name: "liveOpenClass_create_success",
              query: {
                ...queryList,
                teacherId: live.teacherId || 0,
              },
            });
          } catch (err) {
            if (err.code === "50390001") {
              message.error("创建直播失败，请稍后重试");
            }
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      submitLoading.value = false;
    }, 300);

    initData();

    return {
      lodash: _,
      routerHeaderInfo,
      isEdit,

      spinning,
      live,
      liveRules,

      disabledDate,
      handleStartTime,

      timeOptions,

      handleImgUrl,

      internalTeacher,
      externalTeacher,
      handleTeacherSource,

      selectStaffModalVisible,
      checkedKeys,
      staffList,
      saveStaff,
      deleteAssistant,

      chooseInternalTeacher,

      formRef,
      submitLoading,
      saveLive,

      validateForm,
      timeError,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;

      if (from.path === "/marketingUtil/liveColumnCreateSuccess") {
        _.assign(vm.routerHeaderInfo[length - 2], {
          path: "/marketingUtil/liveColumnStatistic",
          name: "专栏详情",
          query: from.query,
        });
        return;
      }

      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },
});
</script>

<style lang='less' scoped>
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 240px;
  aspect-ratio: 16 / 9;
}
</style>